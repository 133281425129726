.table-wrapper {
    border: 2px solid gray;
    box-shadow: gray 2px 2px 2px;
    padding: 20px;
    text-align: center;
}

.mercadopago-button {
    height: 50px;
    width: 50%;
}