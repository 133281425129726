.info_saborizantes>h5{
    font-size: 15px;
    font-weight:bold;
}

.text-black{
    color: black;
}

.text-blue {
    color: #004593 !important;
    font-weight:bold;
}

.input-search {
    margin-bottom: 30px;
    margin-top: 30px;
}