  .App-payment {
    padding: 30px;
  }
  
  .form-actions {
    margin-top: 15px;
  }
  
  .App-cards {
    margin: 0 auto;
    max-width: 1280px;
    text-align: center;
  }
  
  .App-cards-list {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .App-cards-list > * {
    transform: scale(0.4);
  }
  
  .App-highlight {
    font-family: serif;
    margin: 15px auto 0;
    max-width: 300px;
  }
  
  .App-highlight > div {
    padding-left: 40px;
  }
  
  .App-badges {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .App-badges a {
    display: block;
  }
  
  .App-badges a + a {
    margin-top: 18px;
  }
  
  .github__btn {
    align-items: center;
    background-color: #ccc;
    border-radius: 4px;
    color: #444;
    display: flex;
    line-height: 1;
    padding: 8px 12px;
  }
  
  .github__btn:hover {
    color: #444;
    text-decoration: none;
  }
  
  .github__btn img {
    display: inline-block;
    height: 28px;
    margin-right: 8px;
  }
  
  .App-credits {
    background-color: #000;
    color: #fff;
    line-height: 50px;
    text-align: center;
  }
  
  .App-credits a {
    color: #fff;
    font-weight: bold;
  }
  
  @media screen and (min-width: 600px) {
    .App-badges {
      flex-direction: row;
    }
  
    .App-badges a + a {
      margin-top: 0;
      margin-left: 18px;
    }
  }
  