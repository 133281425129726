.carousel{
    margin-bottom: 5%;
}

.carousel-caption{
    left: 4%;
    right: 4%;
    bottom: 10%;
    text-align: left;
}

.carousel-caption>img{
    height: 15vw;
}

.carousel-caption>p{
    color: #004593;
    font-weight: bold;
    font-family: Poppins-Regular;
    font-size: 3vw;
    margin-bottom: 10px;
}

.carousel-caption>h3{
    color: #80232E;
    font-family: Poppins-Regular;
    font-weight: bold;
    font-size: 4vw;
}

.carousel-caption>button{
    background-color: #004593;
    font-family: Poppins-Regular;
    color:white;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 40px 10px 40px;
    border-radius: 15px;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23004593' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23004593' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next,
.carousel-control-prev {
    width: 50%;
}

.carousel-control-next span {
    position: absolute;
    right: 20px;
}

.carousel-control-prev span {
    position: absolute;
    left: 20px;
}