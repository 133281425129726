.active-menu{
    color: #80232e !important;
    font-weight: bold;
}

.no-active {
    color: black;
    font-weight: bold;
}

.no-active:hover {
    color: #80232e;
}