.slider-card-wrapper {
    
}

.img-hover-zoom {
    height: 200px;
    overflow: hidden;
}

.img-hover-zoom img {
    transition: transform .5s ease;
}

.img-hover-zoom:hover img {
    transform: scale(1.2);
}

.img-hover-zoom .btn-card {
    position: relative;
    transition: transform .5s ease;
    bottom: 0px;
    margin: auto;
    left: 0px;
    right: 0px;
    display: none;
}

.img-hover-zoom:hover .btn-card {
    display: block;
    bottom: 5vw;
}